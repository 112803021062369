.App {
  text-align: center;
}

nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: #231f20;
  color: white;
}

.nav-links {
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style: none;
}

a {
  color: white;
  text-decoration: underline;
}

footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 15vh;
  background: #231f20;
  color: white;
}
